import { ModuleWithProviders, NgModule } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import {
  Web3Service,
  ThemeService,
  NDDClientInfoServiceImpl,
  NotificationService,
  NetworksService,
  ScannerService
} from '../services';
import { LoggingService } from '../services/log/service/logging.service';

@NgModule({
  providers: []
})
export class ApiModule {
  static forChild(): ModuleWithProviders<any> {
    return {
      ngModule: ApiModule,
      providers: [
        { provide: NZ_I18N, useValue: en_US },
        GoogleTagManagerService,
        Web3Service,
        ThemeService,
        LoggingService,
        NDDClientInfoServiceImpl,
        NotificationService,
        NetworksService,
        ScannerService
      ]
    };
  }
}
