import { Component, OnInit } from '@angular/core';
import {
  NDDClientInfoServiceImpl,
  ThemeService,
  Web3Service
} from './services';
import { BaseComponent } from './views/layouts/base.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent extends BaseComponent implements OnInit {
  constructor(
    protected web3Service: Web3Service,
    protected themeService: ThemeService,
    protected clientInfoServiceImpl?: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, clientInfoServiceImpl);
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.closeSplash();
  }
}
